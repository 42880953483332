@import 'antd/dist/antd.css';
@import url('https://fonts.cdnfonts.com/css/inter');

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 8px;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

body {
  font-family: 'Inter', sans-serif;
}

p {
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.AuthCodeWrapper {
  display: flex;
  justify-content: center;
}

.AuthInput,
.input {
  width: 61px;
  height: 83px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #f2f3f4;
  background-clip: padding-box;
}

@media screen and (max-width: 768px) {
  .AuthInput,
  .input {
    width: 40px;
    height: 40px;
  }
}

.input:last-child {
  margin-right: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 502px;
  border: 1px solid #bcbbbb;
  border-radius: 10px;
  filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.08));
  overflow: hidden;
}
